/* src/styles/AdStyles.css */
.ad-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    background-color: #ddd;
    padding: 20px;
    box-sizing: border-box;
}

.ad-slot div {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
}
