body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

.app-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
}

.bg-danger {
  background-color: #f8d7da !important;
}

.bg-success {
  background-color: #d4edda !important;
}

.slider-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some space between sliders */
  padding: 20px; /* Add padding to the container */
  border: 2px solid #ccc; /* Add border for a modern look */
  border-radius: 8px; /* Round the corners */
}

.slider-container.highlighted {
  background-color: #f0f0f0; /* Change background color when highlighted */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider-highlight-red {
  border-color: red !important;
}

.slider-highlight-green {
  border-color: green !important;
}

h1 {
  font-size: 2rem;
  color: #333333;
}

label {
  font-weight: 500;
}

button {
  padding: 10px 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: #007bff;
  border-radius: 1.3px;
}

input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #007bff;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #007bff;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: #007bff;
  border-radius: 1.3px;
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #007bff;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #007bff;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}

input[type="range"]::-ms-fill-upper {
  background: #007bff;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}

input[type="range"]::-ms-thumb {
  border: 1px solid #007bff;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: 0px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #007bff;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #007bff;
}

select.form-control {
  appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10.293 12.707a1 1 0 0 1-1.414 0L2.293 6.707a1 1 0 1 1 1.414-1.414L10 10.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7 7a1 1 0 0 1 0 1.414z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 16px 12px, 100%;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 45%;
}

select.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

option {
  color: black;
}

.custom-checkbox .custom-control-label::before {
  border: 2px solid #007bff;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.portfolio-mix.bg-danger {
  background-color: #f8d7da;
}

.portfolio-mix.bg-success {
  background-color: #d4edda;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Optional: Set a background color */
}

#root {
  width: 100%;
  overflow: auto;
}

